:root {
  --primary: #3D55A5;
  --secondary: #9B9B9B;
  --light: #F5F5F5;
  --dark: #161616;
}

.pcourse {
  color: #FFF;
  font-size: 18px;
  margin: 0;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px !important;
}
.bg-grey{
  background-color: #ECECEC !important;
}

.minh180{
  min-height: 190px !important;
  max-height: 190px !important;
}

.btn-square1 {
  width: 90px;
  height: 25px;
  border-radius: 30px!important;
  background-color: #3d55a5;
  color: #fff;
  font-weight: 300 !important;
  padding: 0px;
  font-size: 14px !important;
  text-transform: uppercase;
  margin-top:17px!important;
}
.bg-blue{
  background-color: #3D55A5 !important;
}
.r-45{
  transform: rotate(360deg);
  transition: all .4s linear !important;
}
.r-90{
  transform: rotate(180deg);
  transition: all .4s linear !important;
}
.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** task ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/* notification and profile */
.notifyIcon {
  width: 35px;
  height: 35px;
  background: #3C3C3C;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}

.bellIcon {
  position: relative;
}

.bellIcon::after {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 20px;
  background-color: #FFA845;
  position: absolute;
  top: -3px;
  right: 2px;
}

.afterLogin {
  display: flex;
  gap: 20px;
}

.profileIcon {
  width: 35px;
  height: 35px;
  position: relative;
}

.profileIcon::after {
  content: '\f0d7';
  position: absolute;
  top: 50%;
  right: -14px;
  font-family: 'FontAwesome';
  color: #000;
  transform: translateY(-50%);
}

Image.iconP {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* notification and profile End*/
/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  margin-right: 30px;
  font-size: 14px !important;
  padding: 25px 0;
  color: #3d55a5;
  font-weight: 600;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #3d55a5;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}

.headerImage {
  background-image: url("./Image/trade-schools.jpeg");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImagelog {
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImage1 {
  background-image: url("./Image/online_courses.png");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImage2 {
  background-image: url("./Image/What-are-5G-Cell-Towers.png");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 220px;
}

.background {
  padding: 100px 0px 50px;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}


.bannerHeading {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.bannerHeading h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 0px;
}

.shadow {
  display: flex !important;
  align-items: center !important;
  background: rgba(22, 22, 22, .7) !important;
}

.wrappers {
  position: relative;
  /* max-width: 600px; */
  max-width: 100%;
  width: 100%;
  padding: 20px;
  border-radius: 15px !important;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}

.wrappers h2 {
  position: relative;
  font-size: 18px !important;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers form .input-box {
  margin: 8px auto !important;
    text-align: center;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  margin-right: 0;
  font-size: 14px !important;
  padding: 20px 15px!important;
  color: #3d55a5;
  font-weight: 600;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #3d55a5;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}

.headerImage {
  background-image: url("./Image/trade-schools.jpeg");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background {
  padding: 100px 0px 50px;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}


.bannerHeading {
  display: block;
  height: 100%;
}

.bannerHeading h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 0px;
}
.bannerHeading h4{
    display: block;
    width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.shadow {
  display: flex !important;
  align-items: center !important;
  background: rgba(22, 22, 22, .7) !important;
}

.wrappers {
  position: relative;
  /* max-width: 390px; */
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
  margin: 0 auto;
}

.wrappers h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers form .input-box {
  margin: 8px 0;
  text-align: center;
}

.input-box input:focus,
.input-box input:valid {
  border-color: #00aaa1;
}

form .policy {
  display: flex;
  align-items: center;
}

form h3 {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  margin-left: 8px;
}

.options {
  cursor: pointer;
  width: 300px;
  min-height: 60px;
  max-height: 300px;
  border-radius: 15px;
  background-color: rgb(117, 107, 107);
  box-shadow: 2px 4px 8px #c5c5c5;
  transition: all 300ms;
}

.options.opened {
  overflow-y: scroll;
}

select.form-control.selectOption {
  margin-bottom: 15px !important;
  border-radius: 20px!important;

}

input#submitButton {
  background: #3D55A5;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 30px;
  border-radius: 20px;
  font-size: 13px;
}

.form-control:focus {
  color: #000000;
  background-color: #fff;
  border-color: #3D55A5;
  outline: 0;
  box-shadow: 0 0 5px 2px #3d55a5cf;
}

select.form-control.selectOption {
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #ECECEC;
  color: #000;
  font-size: 12px !important;
}

/* Program Section Css Start */
.programsSection {
  padding: 0;
  margin-top: 30px;
  overflow: hidden;
}
.programsSection_job {
  padding: 0;
  margin-top: 0px;
  overflow: hidden;
}
.m25{
  margin:25px 0!important;
}
.programsSection3{
  margin:25px 0!important;
}
.padding45 {
  padding: 45px 0px;
}

.padding25 {
  padding: 25px 0px;
}

button.btn.btn-primary.btnSidebar {
  border-radius: 20px;
  padding: 10px 25px;
  font-size: 15px;
  background-color: #3D55A5;
  border: none;
  font-family: 'Lato';
  margin-top: 10px;
  min-width:100px!important;
  margin-right:5px;
}
.modal-backdrop{
  z-index: 99999!important;
}
.modal{
  z-index:999999!important;
}

.btnFuture {
  padding: 5px 40px !important;
}

.filterBtn {
  background-color: #c1c1c1 !important;
  color: #000 !important;
}

.checkBox {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px dotted #ccc;
}

input[type=checkbox] {
  accent-color: #3D55A5;
  width: 17px;
  height: 17px;
}

.checkBox label {
    color: #000;
    font-size: 16px;
    
    padding: 10px 0px;
}

.radioBoxRow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.radioBtn {
  accent-color: #3D55A5;
  width: 17px;
  height: 17px;
}


.filterBody {
  background: #ECECEC;
  border-radius: 10px;
  padding: 0px;
}

.radioBox {
  margin-top: 20px;
}

.radioBox p,
.priceRange p {
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  color: #000;
  text-transform: uppercase;
}

.priceRange {
  margin-top: 20px;
}

.radioBoxRow label {
  color: #000;
  font-size: 13px;
}

.stateBox {
  margin-top: 20px;
}

.selectOptionSidebar {
  border-radius: 8px;
  font-size: 14px;
  color: #000;
  background-color: #ffffff !important;
  padding: 6px 15px;
}

.searchProgram {
  margin-bottom: 20px;

   margin-top: 10px;
}

.sideBarFooter {
  display: flex;
  margin-top: 0px;
  gap: 5px;
}

.btnBSidebar {
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 12px;
  background-color: #3D55A5;
  border: none;
}

.RefilterBtn {
  background: none;
  border: 1px solid #3D55A5;
  color: #3D55A5 !important;
}

/* Programs Body  Start*/
.programBox {
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px;
}

.programsContentInfo h5 {
  color: #3C3C3C;
  font-size: 15px;
  margin: 0;
}

.programContent {
  padding: 15px;
}

.programTitle h1 {
  font-size: 25px;
}

.programTitle {
  background: #3D55A5;
  padding: 15px;
 
  box-shadow: 0px 3px 6px #00000029;
}

.programTitle h1 {
  font-size: 16px;
  margin: 0;
  color: #fff;
  font-family: 'Lato', sans-serif;
}
.filterBody h4 {
  color: #000 !important;
}
.programsSection h4 {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
}

.programsContentInfo {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.programsContentInfo .fa {
  color: #FFA845;
}

.loadBtn {
  text-align: center;
  margin-top: 20px;
    margin-bottom: 30px;
}

.loadBtn button {
  background: #3D55A5;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: uppercase;
}

/* Programs Body  End*/

/* Program Section Css End */

/* Tab Systmes Css Start */
.leftSideBtn p {
  margin: 0;
  font-size: 15px;
  padding: 2px 5px 0px;
  font-weight: 500;
}

.plusCercle {
  border: 1px solid #fff;
  width: 20px;
  height: 20px;
  border-radius:50%;
  text-align: center;
  line-height: 17px;
}


.tabSystems {
  padding-bottom: 50px;
}

.tabPanel {
  background: #ECECEC;
  width: 100%;
  height: 100%;
  padding: 84px 49px 0px 58px;
}

.tabPanel p {
  color: #3C3C3C;
}

/* Tab Systmes Css End */


@media only screen and (max-width:767px) {
  ul.tandcLink2 {
    padding: 0;
    list-style: none !important;
    display: unset !important;
    ;
    align-items: center;
    margin: 0;
    gap: 30px;
    margin-bottom: 30px;
  }
  .rr.borederlio h2 {
    width: 100%!important;
    font-size: 20px;
    padding-left: 20px!important;
  }
  .groupSection1 {
    grid-template-columns: 12% 85% 0%!important;
  }
  .rr.borederlio {
    position: relative;
  }
  .borederlio1 {
    position: absolute!important;
    width: 100px!important;
    right: 0!important;
    top: 0!important;
}
.borederlio1 Image {
  width: 50px!important;
  position: absolute!important;
  right: 11px!important;
  bottom: -26px!important;
}
.borederlio1 span {
  font-size: 25px!important;
  right: -14px!important;
  top: -18px!important;
  bottom: 0!important;
}
  .borederlio2 Image {
    width: 40px!important;
    top: 25px;
    left: 10px!important;
}
.tradeTitle {
  width: 100%;
}

  .programsSideBar {
    margin-bottom: 20px;
  }

  .commentProfile {
    grid-template-columns: 12% 86% !important;
    grid-column-gap: 2%;
  }

  .loccss {
    margin-bottom: 30px;
  }

  .quizPagination {
    gap: 6px !important;
  }

  .seachBox {
    z-index: 9999;
  }

  .closeSearch {
    right: 10px !important;
    top: -7px !important;
    font-size: 48px !important;
  }

  .counpro1 p {
    padding: 0px !important;
  }

  .onlinecousectinstu {
    margin-bottom: 30px;
  }

  .bannerHeadingcoun h1 {
    font-size: 40px !important;
  }
}

.price-range-slider {
  width: 100%;
}

.price-range-slider .range-value {
  margin: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}

.price-range-slider .range-value input {
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: initial;
  box-shadow: none;
  border: 1px solid #ced4da;
  margin: 10px 0 10px 0;
  border-radius: 11px;
  padding: 6px 15px;
}

.price-range-slider .range-bar {
  border: none;
  background: #3D55A5;
  height: 5px;
  width: 96%;
  margin-left: 8px;
}
h5.cssh {
  color: #404b71 !important;
  font-size: 18px!important;
  font-weight: 800!important;
  margin: 5px!important;
  text-align: center!important;
}
li.schoolDetails.schoolDetailsss {
  padding: 15px 20px 0px !important;
}

.price-range-slider .range-bar .ui-slider-range {
  background: #3D55A5;
}

.price-range-slider .range-bar .ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #3D55A5;
  border: 2px solid #3D55A5;
  height: 17px;
  width: 17px;
  top: -0.52em;
  cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle+span {
  background: #3D55A5;
}

.ui-state-focus,
.ui-state-active {
  border: 2px solid #3D55A5 !important;
}

/*--- /.price-range-slider ---*/

span.priceBudget {
  color: #000;
  font-size: 13px;
}

div#slider-range {
  margin-top: 20px;
}

/* Programs Body  End*/

/* Program Section Css End */

@media only screen and (max-width:767px) {
  .programsSideBar {
    margin-bottom: 20px;
  }
}

/* Tab Systmes Css Start */

.leftSideBtn {
  background: #3D55A5;
  color: #fff;
  border-radius: 19px;
  padding: 0px 25px;
  display: block;
  align-items: center;
}
.submitButton {
  background: #3D55A5;
  border: 0px solid #3D55A5;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  width:100%;
  text-align: center;
  padding:10px 0!important;
}
.input-box {
  /* float: revert; */
  text-align: center;
}
.tabSystems {
  padding-bottom: 50px;
}

.tabPanel {
  background: #ECECEC;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.tabPanel p {
  color: #3C3C3C;
}

/* Tab Systmes Css End */
/* related Section Css End */
.related span {
  color: #1515dda3;
  font-size: 12px;
  font-weight: 500px;
}

.related p {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
}

.related-p {
  padding: 0px;
  margin-top: 25px;
}

.related {
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-weight: 800;
  font-size: 20px;
}

.swiper-pagination-bullet-active {
  background-color: #3D55A5 !important;
}

.related1 {
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-weight: 800;
  font-size: 20px;
}

.relatedResource {
  padding: 0px 10px;
}

/* related Section Css End */
.call-fixed-button1 {
  width: 36px;
  position: fixed;
  bottom: 0px;
  top: 440px;
  right: 0px;
  z-index: 10000;
  float: right;
  border-radius: 12px 12px 0px 0px;
}

.pulsating {
  box-sizing: border-box;
  height: 166px;
  width: 56px;
  border-radius: 20px;
  background: #f98a00;
  color: #000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: pulsate 1.5s ease infinite;
}


button .inner-pulse {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  height: 45px;
  margin: auto;
  min-width: 45px;
  width: 45px;
  background: transparent;
}

.pulsating p {
  text-align: center;
  padding: 2px 42px 4px 25px;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.tb-text {
  writing-mode: tb
}

.bt-text {
  writing-mode: tb;
  transform: rotate(-180deg);
}

.test-design h5 {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.test-design p {
  color: #221f1f !important;
  text-align: left;
  margin-top: 0px !important;
  margin-bottom: 20px!important;
}

/*.test-design p::before {
  content: '' !important;
  position: absolute !important;
  left: 13% !important;
  height: 1px !important;
  width: 8% !important;
  margin-top: -2% !important;
  background: #d5763e !important;

  transform: translateX(-50%) !important;
}*/

.test-design h6::before {
  content: '' !important;
  position: absolute !important;
  left: 13% !important;
  height: 1px !important;
  width: 8% !important;
  margin-top: -2% !important;
  background: #d5763e !important;

  transform: translateX(-50%) !important;
}

.Image-design Image {
  border-radius: 20px !important;
}

.Image-design h5 {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.Image-design p {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.Image-design1 p {
  color: #221f1f !important;
  text-align: left;

}

.Image-design h6::before {
  content: '' !important;
  position: absolute !important;
  left: 13% !important;
  height: 1px !important;
  width: 8% !important;
  margin-top: -2% !important;
  background: #d5763e !important;

  transform: translateX(-50%) !important;
}

.Image-design h6 {
  color: #221f1f !important;
  text-align: left;
  font-size: 18px;
  margin-top: 50px !important;
}

/* Footer Css Start */
footer.footerSection {
  padding: 50px 0px;
  background-image: linear-gradient(0deg, #ECECEC, #ECECEC, #ECECEC03);
  border-top: 1px solid #c4c4c4;
  margin-top: 150px;
}
.banneerSection {
  
margin-top: 60px ;
 
}
.programsSection h4 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
}
.footerTitle {
  margin-bottom: 27px;
}

.footerTitle h3 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-weight: bold;
}

.footerBody ul {
  list-style: none;
  padding: 0;
}

.b-grey{
  border: 5px solid #dedbdb !important;
}



.subscirpSection {
  width: 50%;
  margin: 0 auto;
  padding-top: 20px;
}

.inputField {
  width: 100%;
  position: relative;
}

h1.subscribeTitle {
  font-size: 18px!important;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

.inputField input {
  width: 100%;
  border-radius: 22px;
  border: none;
  background: #fff;
  padding: 5px 10px;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.inputField input::placeholder {
  color: #000;
  font-style: italic;
}

button.subscribeBtn {
  position: absolute;
  right: 0;
  /* background: #3D55A5; */
  border: none;
  height: 100%;
  /* border-radius: 22px; */
  color: #fff;
  padding: 0px 20px !important;
  font-size: 20px !important;
}

.followUs {
  margin: 20px 0px;
}

ul.solialLink {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

a.socialLinkItem {
  color: #000;
  padding: 0px 20px;
  font-size: 20px;
}

ul.tandcLink {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

ul.tandcLink a {
  color: #000;
  text-decoration: underline;
  padding: 0px 20px;
}

.copyright p {
  color: #000;
  margin: 0;
  font-size: 14px;
}

.footerBottom {
  background: #ECECEC;
  text-align: center;
  padding-bottom: 30px;
}

/*------Post page design start-----*/
body {
  font-family: 'Lato', sans-serif;
  color: #000;
}

.navbar.sticky-top {
  box-shadow: 0px 0px 5px;
  height: 60px;
}

.newPostContainer input[type="text"] {
  width: 100%;
  border-radius: 34px;
  border: none;
  padding-left: 25px;
  outline: none;
}

.newPostContainer {
  position: relative;
  height: 45px;
  background: #fff;
  border-radius: 30px;
}

.profileIconPost.profileImage1 {
  width: 40px;
}

.profileIconPost, .postPImage, .commentUserImage, .groupIcon {
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px !important;
  overflow: hidden;
}

Image.newPostProfileImage,
.commentUserImage Image,
.postPImage Image,
.groupIcon Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newPostAccessory {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.newPostContainer input[type="text"]::placeholder {
  font-style: italic;
  color: #3C3C3C;
  font-size: 14px;
}

button.postButton {
  background: #3D55A5;
  color: #fff;
  padding: 6px 34px;
  height: 100%;
  border-radius: 33px;
  border: none;
  font-size: 14px;
}

.postBtn .fa-clock-o {
  font-size: 25px;
  color: #616161;
}

.postBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.postSection {
  padding-top: 60px;
}

.postAccessoryIcons ul {
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  padding-left: 60px;
  margin-top: 10px;
}

.postAccessoryIcons ul li {
  color: #3C3C3C;
  padding-right: 14px;
}

.trendingButton button {
  border: none;
  background: #3C3C3C;
  color: #fff;
  padding: 5px 15px;
  border-radius: 27px;
  font-size: 12px;
}

.trendingButton button .fa {
  padding-right: 7px;
}

.trendingBG {
  background-color: #FFA845 !important;
}

.freshBg {
  background-color: #3D55A5 !important;
}

.trendingButton {
  padding: 10px 0px;
}

p.postAuthor {
  margin: 0;
  font-size: 14px;
  color: #3C3C3C;
}

small.postAuthorUsername {
  font-size: 11px;
  color: #3C3C3C;
}

.postProfileLeft {
  display: flex;
  gap: 15px;
}

.postAutorName {
  display: flex;
  flex-direction: column;
}

.postDate li {
  color: #3C3C3C;
  font-size: 11px;
}

.postDate ul {
  padding-left: 25px;
}

.viewShowProfile {
  display: flex;
  justify-content: space-between;
}

.postDownArrow i.fa.fa-caret-down {
  color: #3C3C3C;
  font-size: 23px;
}

.postProfileRight {
  display: flex;
  align-items: center;
}

.viewPostSection {
  background: #fff;
  padding: 20px;
  border-radius: 30px;
  margin-top: 20px;
}

.postImage Image {
  width: 100%;
}

.postImage {
  margin: 15px 0px;
}

.postTitle h1 {
  font-size: 20px;
  color: #3C3C3C;
}

.postTitle p {
  color: #3C3C3C;
  font-size: 14px;
}

.postTag {
  border-bottom: 1px solid #3D55A5;
  padding-bottom: 10px;
  color: #3D55A5;
}

.postTag a {
  color: #3D55A5;
  font-size: 13px;
}

.postShareLike {
  display: flex;
  align-items: center;
  gap: 40px;
  color: #3D55A5;
  margin-top: 10px;
}

.postLike,
.postShare {
  font-size: 20px;
}

.space30 {
  height: 30px;
}

.space15 {
  height: 15px;
}

.autorComment p {
  color: #3C3C3C;
  font-size: 14px;
  margin: 0;
}

.autorComment {
  padding-left: 50px;
  margin-top: 10px;
}

.postSidebar {
  margin-top: 20px;
}

.sideBarSearchProgram .wrappers {
  border-radius: 15px;
}

.postSendBtn {
  display: none;
}

.commentProfile {
  display: grid;
  width: 100%;
  grid-template-columns: 4% 94%;
  grid-column-gap: 2%;
  margin-top: 20px;
}

.commentInput {
  background: #ECECEC;
  position: relative;
  border-radius: 26px;
  padding: 0px 17px;
  display: flex;
  align-items: center;
}

.commentInput input[type="text"]::placeholder {
  font-style: italic;
  font-size: 14px;
  margin-top: -4px !important;
}

.commentInput input[type="text"] {
  width: 100%;
  border-radius: 26px;
  background: no-repeat;
  border: none;
  outline: none;
  height: 100%;
}

.commentInput button {
  position: absolute;
  right: 6px;
  border: none;
  background: #3D55A5;
  color: #fff;
  border-radius: 26px;
  padding: 1px 15px;
  font-family: 'Lato';
}

h4.gName {
  font-size: 14px;
  color: #3C3C3C;
  font-weight: 600;
  margin: 0;
}

.divider {
  width: 50%;
  height: 2px;
  background: #3D55A5;
  text-align: center;
  margin: 20px auto;
  margin-top: 30px;
}

.sideBarTitle h1 {
  font-size: 27px;
  font-family: 'Lato';
  text-align: center;
  font-weight: 800;
  color: #3C3C3C;
  margin: 0;
}

i.fa.fa-arrow-right {
  margin-top: 8px;
  margin-left: 10px;
}
i.fa.fa-arrow-right {
  margin-top: 8px;
  margin-left: 10px;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  /* border-color: rgba(0, 0, 0, 0.87); */
  border: none;
}
a#v-tabs-home-tab {
  padding: 10px;
}
a#v-tabs-messages-tab {
  padding: 10px;
}
a#v-tabs-profile-tab {
  padding: 10px;
}
.nav-link {
    color: #171818 !important;
 }
.postGroups {
  padding: 28px;
  background: #fff;
  border-radius: 10px;
}
p.sucressful {
  text-align: center;
  color: green;
}
i.fa.fa-caret-down.needhelp {
  margin-left: 48%;
}


p.groupFollowers {
  color: #3D55A5;
  margin: 0;
  font-size: 14px;
  margin-top: -3px;
}
p.groupFollowers1 {
  color: #000;
  margin: 0;
  font-size: 14px;
  margin-top: -3px;
}

.groupSection {
  display: grid;
  gap: 6px;
  grid-template-columns: 12% 72% 12%;
  margin: 15px 0px;
}

.groupAdd {
  width: 35px;
  height: 35px;
  border: 2px solid #3D55A5;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}

.gPlus {
  color: #3D55A5;
}

@media only screen and (max-width:767px) {
  .trendingButton button {
    margin-bottom: 5px;
  }

  .postSendBtn {
    display: block;
  }

  .postSendText {
    display: none;
  }

  button.postButton {
    padding: 9px 14px;
  }

  .newPostContainer input[type="text"]::placeholder {
    font-size: 10px;
  }

  .profileIconPost.profileImage1 {
    width: 55px;
  }
}

/* Course Details Page Css Start */
.courseTitleD {
  color: #fff;
}

.overViewBg {
  background: #ececec;
  padding: 55px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
}

.overViewContent h1 {
  font-size: 25px;
  font-family: 'Lato';
}

.courseDetalsPrice h3 {
  font-size: 25px;
  color: #000;
}

h4.crossMrp {
  color: #3D55A5;
  text-decoration: line-through;
  font-size: 30px;
}

.courseDetalsPrice {
  text-align: center;
}

h1.coursePrice {
  color: #FFA845;
  font-size: 50px;
}

.courseDetalsPrice button {
  background: #3D55A5;
  color: #fff;
  border: none;
  width: 115px;
  height: 40px;
  border-radius: 39px;
}

.eligibilityContnet h1,
.courseSkills h1 {
  font-size: 25px;
  font-family: 'Lato';
  margin-bottom: 20px;
  color: #000;
}

.skillsTag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.skillsTag a {
  background: #ECECEC;
  padding: 5px 15px;
  border-radius: 20px;
  color: #000;
  font-size: 15px;
}

.EligibilityorSkills {
  padding: 30px;
}

.borderOutline {
  border: 3px solid #ECECEC;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.courseOverviewSection {
  padding-bottom: 80px;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #3D55A5 !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}

.accordion-button {
  color: #fff !important;
  background-color: #3D55A5 !important;
}

button.accordion-button {
  border-radius: 19px !important;
}

.accordion-item {
  border: none !important;
  background-color: #ECECEC !important;
  margin-bottom: 10px !important;
  border-radius: 19px !important;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
}

.plusCercle.pCerclePadding {
  margin-right: 10px !important;
}

ul.accourdionCourseBullot {
  padding-left: 50px !important;
}

.coreModuleRight {
  background: #ECECEC !important;
  border-radius: 30px !important;
  padding: 30px !important;
}

.coreModuleRight h1 {
  font-size: 20px !important;
  color:#000;
}

.coreModuleRight p {
  font-size: 13px !important;
}

.coreModuleRight p a {
  color: #2A38FF !important;
}

h1.coreModuleH {
  color: #3D55A5 !important;
  /* font-family: 'Lato' !important; */
  font-size: 25px !important;
  font-weight: bold!important;
  margin-bottom: 20px !important;
  margin-top: 20px;
}

.space {
  height: auto;
}

.outcomeSection {
  padding: 50px 0px;
}

.outcomeContent h1 {
  color: #3D55A5;
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.outcomeContent li {
  font-size: 20px;
  margin-bottom: 10px;
}

.pcourse .fa {
  color: #FFA845;
  margin-right: 10px;
}

.courseDImage {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  overflow: hidden;
}

.courseDImage Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.programsContentInfo1 {
  display: flex;
  color: #fff;
  align-items: center;
  gap: 20px;
}

.programsContentInfo1 h5 {
  color: #fff;
  font-family: 'Lato';
  font-size: 17px;
  margin: 0;
}

/* Search Bar Css Start */

form.searchForm input[type="search"] {
  width: 100%;
  padding: 7px;
  color: #000;
  border: none;
  border-radius: 20px;
}

form.searchForm {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

form.searchForm button {
  background: #3D55A5;
  border: none;
  color: #fff;
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.seachBox {
  z-index: 100;
  width: 100%;
  height: auto;
  padding: 40px 0px;
  background: #ECECEC;
  position: absolute;
  top: -100%;
  transition: top 0.5s;
}

.closeSearch {
  position: absolute;
  right: 70px;
  top: 27px;
  transform: rotate(45deg);
  font-size: 40px;
  font-weight: 300;
  cursor: pointer;
}

/* online counselling */

.headerImagecoun {
  background-image: url(./Image/1637330549549.jpg);
  top: 0;
  left: 0;
  padding-top: 20%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 490px;
  overflow: hidden;
  position: relative;
}

.headerImagecoun2 {
  background-image: url("./Image/bg2.jpg") !important;
  background-position: top;
}

.headerImagecoun1 {
  background-image: url("./Image/how-career-counselors-can-help-you-find-your-calling-4.png") !important;
  background-position: top;
}

.bannerHeadingcoun {

  height: 100%;
  align-items: flex-end;
}

.futureBox {
  display: flex;
  align-items: center;
  height: 100%;
}

.bannerHeadingcoun h1 {
  color: #fff;
  font-size: 60px;
  font-family: "Arial", sans-serif;
  margin-top: 0px;
}

.backgroundcoun {
  padding: 0px 0px 20px;
  background: linear-gradient(1deg, black, black, #0000, #fff0, #fff0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.onlinecousectin {
  background-color: #ECECEC !important;
  border-bottom-right-radius: 30px;
  padding: 67px 0px !important;

}

.futurecolor h1 {
  text-align: right;
  color: #FFA845;
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.programTitle1 h1 {
  font-size: 25px;
}

.programTitle1 {
  background: #3D55A5;
  padding: 9px 30px 9px 30px;
  color: #fff;
  border-radius: 69px;
  box-shadow: aliceblue;
  border: 1px solid #3D55A5;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%);
  background-position: calc(100% - 21px) calc(1em + -2px), calc(100% - 15px) calc(1em + -2px), 100% 0;
  background-size: 7px 7px, 7px 9px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.programTitle1 option {
  font-size: 16px;
  background: #3D55A5;
  padding: 6px 30px 6px 30px;
  color: #fff;
  border-radius: 19px;
  box-shadow: aliceblue;
  border: 1px solid #3D55A5;
  ;
  margin: 0;
  text-align: center;
  color: #fff !important;
  font-family: 'Lato', sans-serif;
}

.border1 {
  border: 3px solid #ECECEC;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 25px!important;
}

.onlinecousectinp {
  padding: 39px 30px 20px 30px !important;
}

.loccss h3 {
  text-align: center !important;
  font-size: 20px;
  font-family: 'Lato';
}

.loccss {
  text-align: center !important;
}

.programTitle2 {
  background: #3D55A5;
  padding: 10px;
  border-radius: 19px;
  box-shadow: 0px 3px 6px #FFA845;
  width: 90%;
}

.programTitle1 h1 {
  font-size: 18px;
}

.programTitle2 {
  background: #FFA845;
  padding: 4px 0px;
  border-radius: 19px;
  border: 1px solid #ffa845;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #3C3C3C 50%), linear-gradient(135deg, #3C3C3C 50%, transparent 50%);
  background-position: calc(100% - 21px) calc(1em + -2px), calc(100% - 15px) calc(1em + -2px), 100% 0;
  background-size: 7px 7px, 7px 9px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.programTitle2 option {
  font-size: 16px;
  margin: 0;
  text-align: center;
  color: #000;
  font-family: 'Lato', sans-serif;
}

.programsSection1 {
  padding: 0px 0px 30px 0px;
}
.m25{
  margin:25px!important;
}
.onlinecousectinstu {
  background-color: #ECECEC !important;
  border-radius: 10px;
  padding: 15px !important;
  height: auto;
  margin-bottom: 20px;
}

.onlinecousectinstu1 {
  background-color: #ECECEC !important;
  border-radius: 10px;
  padding: 15px !important;
  height: auto;
  margin-bottom: 25px;
}

.onlinecousectinstu Image {
  width: 124px !important;
  text-align: center !important;
}

.onlinecousectinstu1 Image {
  width: 89px !important;
  text-align: center !important;
}

.onlinecousectinstu h3 {
  color: #3D55A5 !important;
  padding: 20px;
  text-align: center !important;
  padding-bottom: 0;
  margin: 0;
}

.onlinecousectinstu1 h3 {
  color: #3D55A5 !important;
  padding: 20px;
  text-align: center !important;
  padding-bottom: 0px;
  margin: 0;
}


.counpro h6 {
  text-align: center;
}

.counpro p {
  text-align: center;
  font-size: 16px;
}

.counpro a {
  color: #3D55A5;
  text-align: center;
  text-decoration: underline;
}

.counpro1 h3 {
  text-align: center;
  color: #3D55A5;
  font-weight: 800;
  font-family: 'Lato';
  margin-bottom: 20px;
}

.counpro1 p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 60px;
  color: #3C3C3C;
}

.counpro1 a {
  color: #3D55A5 !important;
  text-align: center !important;
  ;
}

.pp p {
  padding-left: 25px;
  padding-right: 25px;
  text-align: left;
  margin-bottom: 47px;
}

.programBox1 {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px;
  background-color: #ECECEC;
}

.pp a {
  color: blue;
}

/* Pathfinder quiz */
.pathFinder {
  padding: 50px 0px;
}

h1.quizTitle {
  font-size: 45px;
  font-family: 'Lato';
  color: #3D55A5;
  margin-bottom: 30px;
  font-weight: 800;
}

.quizHeader {
  background: #3D55A5;
  width: 100%;
  height: 80px;
  border-radius: 19px;
}

.pathFinderQuizBody {
  background: #ECECEC;
  height: 100%;
  border-radius: 30px;
  padding-bottom: 30px;
}

.quizPagination {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

span.pagiBullote {
  width: 13px;
  height: 13px;
  background: #979797;
  display: block;
  border-radius: 10px;
}

span.pagiBullote.active {
  background: #3C3C3C;
}

.quizNum {
  text-align: center;
}

.quizContent {
  width: 100%;
  height: 100vh;
}

.sideBarSearchProgram {
  margin-top: 20px;
}

.prorfolio {
  display: flex;
  gap: 50%;
  font-size: 18px;
  list-style: none;
}

.pad {
  padding: 6% !important;
}

.copyright1 {
  margin-top: 2%;
}

.loginborder {
  padding: 1%;
  border-top: 1px solid #cccccc8a;
}

body {
  overflow-x: hidden;
}

ul.tandcLink1 {

  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 2%;
}

ul.tandcLink1 a {
  list-style: none;
  color: #000;
  font-size: 14px;

  margin-top: 3%;
}

.copyright1 p {
  color: #000;
  margin: 0;
  font-size: 14px;
  margin-top: 2%;
  margin-bottom: 20px;
}

.countryFlag Image {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  object-fit: cover;
  object-position: left;
}

.termsLink.linkRight1 ul.tandcLink1 {
  justify-content: flex-end;
  gap: 30px;
}

.countryFlag {
  text-align: center;
  margin-top: 40px;
}

ul.tandcLink2 {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 30px;
  margin-bottom: 30px;
}

ul.tandcLink2 a {
  list-style: none;
  color: #000;
  font-size: 14px;

}

.copyright2 p {
  color: #000;
  margin: 0;
  font-size: 14px;

}

.logininpute {
  margin-bottom: 25px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000;
  font-size: 14px;
}



article {
  min-width: 230px;
  margin: 0 auto;
}

.h11 {
  display: flex !important;
  align-items: center !important;
  margin: 0;
}

.h11:before,
.h11:after {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  background: #a39d9d !important;
}

.h11:before {
  margin: 0 20px 0 0 !important;
}

.h11:after {
  margin: 0 0 0 20px !important;
}

.forgetP {
  margin-top: -13px;
  font-size: 15px;
}

.col-md-12.section-h.py-4 {
  padding-top: 10px !important;
}

footer.lFooter {
  background: #fff;
  padding-top: 70px;
}

.termsLink2.linkRight ul.tandcLink2 {
  justify-content: flex-end;
}

.headerImagesign {
  background-image: url(./Image/backsign.jpg);
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundsign {
  padding: 70px 0px 70px;
}

.radioBoxRowsign {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.ss {
  background-color: #F5F7FA;
  padding: 20px 0px 0px 0px;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
}

.buttonsign {
  border-radius: 20px;
  padding: 5px 40px;
  font-size: 15px;
  background-color: #3D55A5;
  border: none;
  text-align: center;
  font-family: 'Lato';
  margin-top: 10px;
}

h6.accoTypeTitle {
  color: #3D55A5;
  font-size: 15px;
  font-family: 'Lato';
  margin: 0;
  font-weight: bold;
}

span.accouDes {
  color: #3D55A5;
  font-size: 15px;
}

ul.accountTypeDetals li {
  font-size: 12px;
  color: #687173;
}

ul.accountTypeDetals {
  padding-left: 15px;
  margin: 0;
}

.SignUpForm form {
  margin: 0;
}

.buttonsignUp {
  border-radius: 20px!important;
  padding: 5px 40px!important;
  font-size: 15px;
  background-color: #3D55A5!important;
  border: none;
  text-align: center;
  font-family: 'Lato';
  margin-top: 30px;
}

.formTitle {
  text-align: center;
  margin-bottom: 30px;
}

.formTitle h6 {
  font-size: 25px;
  color: #687173;
  margin: 0;
}

.formTitle p {
  font-size: 15px;
  color: #687173;
  margin: 0;
}
.groupSectionll {

  gap: 7px;
  border-radius: 30px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 12% 72% 12%;
  margin: 15px;
}
h4.gNamell {
  font-size: 16px;
  color: #4700ec;
  text-align: left;
  font-weight: 500;
  line-height: px;
  margin: 0px 1px 0px 9px;
}
.text-blue a:hover {
  color: #ffa845 !important;
}
.rightSection .searchBox ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.groupSection1 {
  display: grid;
  gap: 7px;
  border-radius: 30px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 12% 72% 12%;
  margin: 8px 0px;
}
.groupSection2 {
  display:flex;

  border-radius: 30px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 12% 72% 12%;
  margin: 8px 0px;
}

.test-design1 h1 {
  color: #3D55A5;
font-size: 55px;
}
.test-design1 h2 {
  color: #1E1E1E;
font-size: 35px;
font-weight: 300;

padding-right: 30px;
}
.test-design1 h5 {
  color: #221f1f !important;
  text-align: left;

}

.test-design1 p {
  margin-top: 0;
  padding-top: 45px;
  margin-bottom: 0px;
  font-size: 16px !important;
}

.test-design1 p::before {
  content: '' !important;
  position: absolute !important;
  left: 13% !important;
  height: 1px !important;
  width: 8% !important;
  margin-top: -3% !important;
  background: #d5763e !important;

  transform: translateX(-50%) !important;
}

.accordion-header {
  margin-bottom: 0;
  display: flex;
}

.accordioniteli {
  border: none;
  background-color: #ECECEC;
  margin-bottom: 10px;
  border-radius: 19px;
}

.borederlio {
  color: #fff;
  background-color: #3D55A5;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.rr.borederlio {
  background-color: #3d55a5;
  display: flex;
  border-radius: 20px;
}

.borederlio1 Image {
  width: 80px;
  position: absolute;
  right: 20px;
  bottom: 31px;
}

.borederlio2 Image {
  width: 70px;
  position: relative;
  justify-content: space-around !important;
  top: 25px;
  left: 20px;
  border-radius: 10px;
}

.rr.borederlio h2 {
  color: #fff;
  padding-top: 4%;
  width: 500px;
  padding-left: 50px;
  font-weight: 400;
  font-family: 'Lato';
}

ul.accourdionCourseBullot1 {
  padding-left: 21px;
  margin-top: 25px;
}

.accourdionCourseBullot1 li {
  /* line-height: 30px; */
  padding: 10px;
  font-size: 18px;
}

.borederlio1 span {
  position: absolute;
  font-weight: 800;
  color: #3D55A5;
  font-size: 45px;
  right: -24px;
  top: -33px;
  z-index: 9999999;
  bottom: -149px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Lato';
}

.borederlio1 {
  position: relative;
  width: 100%;
}


/* Style the tab */
.tab {
  overflow: hidden;
 
}

/* Style the buttons inside the tab */
.tab div {

  cursor: pointer;
  
}
/* Style the tab content */
.tabcontent {
  display: none;
 
}
.tabcontent.active {
  display: block;
 
}
.sideBarTitle p {
  font-size: 12px;
  font-family: 'Lato';
  text-align: center;
  color: #3C3C3C;
  margin: 0;
}
.groupIcon1 {
  width: 100px;

  border-radius: 30px;
 
}
.bor
{
border-bottom: 1px solid  #ccc;
padding: 10px;
}
.topborder{
  box-shadow: 0px 0px 4px;
    height: 60px;
    padding: 10px;
}


/* Message Page Css */
.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: none;
}
.activeMessage {
 background-color: #ECECEC;
}
td a {
  color: #1347A0;
  text-decoration: none;
}
td.nameM {
  color: #323232;
  font-weight: 600;
}
.sMessage {
  color: #323232;
}
.viewMessage {
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #0000001f;
  padding-right: 5px;
}
.viewMessage .viewShowProfile {
  height: 362px;
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 20px;
}
.messageSidebar {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #0000001f;
}
.messageGrop {
  padding: 10px;
  border-radius: 30px;

}
.availableMessage {
  background: #F5F7FA;
  padding: 15px;
  text-align: center;
  font-size: 11px;
  border-radius: 15px;
  box-shadow: 0px 5px 6px #0000001f;
}
.dropdown-toggle{
  vertical-align: middle;
  font-size: 20px;
  
}
.btnConselling{
  color: black !important;
}
.availableMessage p {
  margin: 0;
}
.messageGrop .form-group {
  display: none;
}
li.activePagination.active {
  color: #1347A0;
}
li.activePagination,
li.paginationArrow {
  padding: 0px 2px;
  cursor: pointer;
}
.pagination {
  justify-content: flex-end;
}

.MessageTitle {
  font-size: 20px;
  margin-bottom: 20px;
}
button.newMessage {
  width: 168px;
  height: 36px;
  color: #1347A0;
  border: 2px solid #1347A0;
  border-radius: 30px;
  outline: none;
  font-weight: 600;

}
.newMessageButton {
  text-align: right;
  margin-bottom: 15px;
}

.datapri {
  background: #fff !important;
  /* background-position: 10px 12px; */
  background-repeat: no-repeat !important;
  width: 100% !important;
  border-left: 4px solid #0f1878 !important;
  font-size: 16px !important;
  /* border-radius: 15px; */
  padding: 1px px 1px 10px !important;
  /* margin-bottom: 12px; */
}
.postSection{
  background-color: #ECECEC;
}

      #myUL {
         list-style-type: none;
         padding: 0;
         margin: 0;
      }

      #myUL li a {
         border: 1px solid #ddd;
         margin-top: -1px;
         /* Prevent double borders */
         background-color: #f6f6f6;
         padding: 12px;
         text-decoration: none;
         font-size: 18px;
         color: black;
         display: block
      }

      #myUL li a:hover:not(.header) {
         background-color: #eee;
      }

      .datapri {
         background: #fff !important;
         /* background-position: 10px 12px; */
         background-repeat: no-repeat !important;
         width: 100% !important;
         border-left: 4px solid #0f1878 !important;
         font-size: 16px !important;
         /* border-radius: 15px; */

         padding: 1px px 1px 10px !important;

         /* margin-bottom: 12px; */
      }

      .datapri p {
         padding-top: 10px;
         margin: 0;
         padding-bottom: 10px;
         padding-left: 5px;
      }

      .ser li {
         padding: 4%;
         list-style: none;
      }

      .ser a {
         color: #000;

      }

      .h6font {
         font-size: 16px !important;
      }

      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
         border-color: inherit;
         border-style: none !important;
         border-width: 0;
      }
      .messageSidePadding {
    padding-left: 60px;
}
ol, .ser, dl {
    margin-top: 0;
    margin-left: -10%;
    margin-bottom: 1rem;
    left: 0px !important;
}
.HeadingTitleSetting {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    padding: 0px 0px;
    margin: 0;
}
.programsSection h4{
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #3d55a5;
}
blockquote{
  font-size: 18px;
  font-style: italic;
}
.mtb25{
  margin:15px 0!important;
}
.ss{
  padding: 10px!important;
}
.programTitle h3{
  color:#fff;
  font-size: 18px;
  font-weight: bold;
}
@media (max-width:767px){
   .messageSidePadding {
      padding-left: 0;
   }
   .messageGrop {
      padding: 30px 20px;
   }
   table#table-id tr td {
      padding: 8px 10px;
   }
   .HeadingTitleSetting {
      padding: 15px 10px;
   }
}
img.iconP {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
 .courseDimg img{
  width: 600px;
  height: 600px;
 }
 .swiper {
  height: auto;
}
.MessageTitle {
  font-size: 20px;
  margin-bottom: 20px;
}
button.newMessage {
  width: 168px;
  height: 36px;
  color: #1347A0;
  border: 2px solid #1347A0;
  border-radius: 30px;
  outline: none;
  font-weight: 600;

}
.newMessageButton {
  text-align: right;
  margin-bottom: 15px;
}

.viewShowProfile1{
  padding:10%;
}
      .viewPostSection {
         height: 430px;
         overflow-x: hidden;
         overflow: scroll;
         overflow-x: hidden;

         width: 80%;
         max-width: 600px;
         margin: 0 auto;
         padding: 3em;


      }
      .css-1v5z18m {
        width: 260px !important;
    }
      .messageGrop hr {
    display: none;
}
.messageGrop {
    padding: 30px 40px;
    border-radius: 30px;
}
table#table-id tr td {
    padding: 25px 10px;
}

      /* width */
      .viewPostSection::-webkit-scrollbar p {
         width: 10px;
      }

      /* Track */
      .viewPostSection::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      .viewPostSection::-webkit-scrollbar-thumb {
         background: #888;
      }

      /* Handle on hover */
      .viewPostSection::-webkit-scrollbar-thumb:hover {
         background: #555;
      }

      .viewShowProfile::-webkit-scrollbar {
         width: 2px;
      }

      /* Track */
      .viewShowProfile::-webkit-scrollbar-track {
         background: #fff;
      }

      /* Handle */
      .viewShowProfile::-webkit-scrollbar-thumb {
         background: #888;
      }

      /* Handle on hover */
      .viewShowProfile::-webkit-scrollbar-thumb:hover {
         background: #555;
      }

      #myInput {
         background-image: url(./Image/searchicon.png);
         background-position: 10px 12px;
         background-repeat: no-repeat;
         width: 100%;
         font-size: 16px;
         border-radius: 15px;
         padding: 7px 20px 7px 40px;
         border: 1px solid #ddd;
         margin-bottom: 40px;
         margin-top: 20px;
      }

      #myUL {
         list-style-type: none;
         padding: 0;
         margin: 0;
      }

      #myUL li a {
         border: 1px solid #ddd;
         margin-top: -1px;
         /* Prevent double borders */
         background-color: #f6f6f6;
         padding: 12px;
         text-decoration: none;
         font-size: 18px;
         color: black;
         display: block
      }

      #myUL li a:hover:not(.header) {
         background-color: #eee;
      }

      .datapri {
         background: #fff !important;
         /* background-position: 10px 12px; */
         background-repeat: no-repeat !important;
         width: 100% !important;
         border-left: 4px solid #0f1878 !important;
         font-size: 16px !important;
         /* border-radius: 15px; */

         padding: 1px px 1px 10px !important;

         /* margin-bottom: 12px; */
      }

      .datapri p {
         padding-top: 10px;
         margin: 0;
         padding-bottom: 10px;
         padding-left: 5px;
      }

      .ser li {
         padding: 2% 4%;
         list-style: none;
      }

      .ser a {
         color: #000;
         text-decoration: none;
      }

      .h6font {
         font-size: 16px !important;
      }

      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
         border-color: inherit;
         border-style: none !important;
         border-width: 0;
      }
      .messageSidePadding {
    padding-left: 60px;
}
ol, .ser, dl {
    margin-top: 0;
    margin-left: -10%;
    margin-bottom: 1rem;
    left: 0px !important;
}
.HeadingTitleSetting {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    padding: 0px 0px;
    margin: 0;
}
@media (max-width:767px){
   .messageSidePadding {
      padding-left: 0;
   }
   .messageGrop {
      padding: 30px 20px;
   }
   table#table-id tr td {
      padding: 8px 10px;
   }
   .HeadingTitleSetting {
      padding: 15px 10px;
   }
}

@media screen and (min-width: 576px) {
  .swiper-container {
    width: 576px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 968px;
  }
}


.courseDimg img {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
}
button.btn.btn-primary.btnBSidebar.RefilterBtn {
  color: #fff !important;
  background-color: #3D55A5!important;
  background: #3D55A5;
    color: #fff;
    border-radius: 19px!important;
    padding: 6px 15px;
    display: grid;
    gap: 10px;
    align-items: center;
    
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background: #3D55A5;
  color: #fff;
  border-radius: 19px!important;
  display: grid;
  align-items: center;
  /* grid-template-columns: 7% 91%; */
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-item {
  border-radius: 19px !important;
}
.nav-pills .nav-link{
  border-radius: 19px !important;
}
@media(max-width:767px){
  .futurecolor h1{text-align: center;}
  .programTitle1{margin:0 auto!important;width:80%!important;}
}
.nav-item a:hover {
  color: #fff !important;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
/* .content1 {
 

  display: none;
} */
#loadMore {
  width: 200px;
  color: #fff;
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: blue;
  transition: .3s;
}
#loadMore:hover {
  color: blue;
  background-color: #fff;
  border: 1px solid blue;
  text-decoration: none;
}
.noContent {
  color: #000 !important;
  background-color: transparent !important;
  pointer-events: none;
}
.backgroundimg {
  padding:140px 0px 50px;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}
.star-ratings svg{
  width:20px!important;
  height:20px!important;
  margin-top:-8px!important;
}
.star-ratings .star-container{
  padding-left:0!important;
}



.borederlio2 img {
  width: 70px;
  position: relative;
  justify-content: space-around !important;
  top: 25px;
  left: 20px;
  border-radius: 10px;
}
.borederlio1 img {
  width: 80px;
  position: absolute;
  right: 20px;
  bottom: 31px;
}
.groupSection1 {
  display: grid;
  gap: 7px;
  border-radius: 30px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 12% 72% 12%;
  margin: 8px 0px;
}
h4.gName {
  font-size: 14px;
  color: #3C3C3C;
  font-weight: 500;
  margin: 0;
}
p.groupFollowers {
  color: #3D55A5;
  margin: 0;
  font-size: 14px;
  margin-top: -3px;
}
.postGroups3 {
  padding: 15px 0px;
  background: #ECECEC;
  border-radius: 30px;
}

.programTitle h1 {
  font-size: 20px !important;
  margin: 0;
  color: #fff;
  font-family: 'Lato', sans-serif !important;
}
li.about {
  width: 250px;
}
.footerBody li.about{
  width:auto;
}


@import "https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/dashboard.scss";
 body {
	 background-color: #fff;
	 color: #202020;
	 font-family: "Montserrat", "Helvetica", "Open Sans", "Arial";
	 font-size: 13px;
}
 a:hover {
	 text-decoration: none;
}
 p, figure {
	 margin: 0;
	 padding: 0;
}
 .navbar {
	 background-color: #1b2431;
}
 .sidebar {
	 background-color: #1b2431;
	 box-shadow: none;
}
 .sidebar .nav-link {
	 border-left: 5px solid transparent;
	 color: #738297;
	 padding: 0.5rem 0.75rem;
}
 .sidebar .nav-link:hover {
	 color: white;
}
 .sidebar .nav-link.active {
	 border-left: 5px solid #738297;
	 color: white;
}
 .sidebar .zmdi {
	 display: inline-block;
	 font-size: 1.35rem;
	 margin-right: 5px;
	 min-width: 25px;
}
 .card-list {
	 width: 100%;
}
 .card-list:before, .card-list:after {
	 content: " ";
	 display: table;
}
 .card-list:after {
	 clear: both;
}
 .card {
	 border-radius: 8px;
	 color: white;
	 padding: 10px;
	 position: relative;
}
 .card .zmdi {
	 color: white;
	 font-size: 28px;
	 opacity: 0.3;
	 position: absolute;
	 right: 13px;
	 top: 13px;
}
 .card .stat {
	 border-top: 1px solid rgba(255, 255, 255, 0.3);
	 font-size: 8px;
	 margin-top: 25px;
	 padding: 10px 10px 0;
	 text-transform: uppercase;
}
 .card .title {
	 display: inline-block;
	 font-size: 8px;
	 padding: 10px 10px 0;
	 text-transform: uppercase;
}
 .card .value {
	 font-size: 28px;
	 padding: 0 10px;
}
 .card.blue {
	 background-color: #2298f1;
}
 .card.green {
	 background-color: #66b92e;
}
 .card.orange {
	 background-color: #da932c;
}
 .card.red {
	 background-color: #d65b4a;
}
 .projects {
	 background-color: #273142;
	 border: 1px solid #313d4f;
	 overflow-x: auto;
	 width: 100%;
}
 .projects-inner {
	 border-radius: 4px;
}
 .projects-header {
	 color: white;
	 padding: 22px;
}
 .projects-header .count, .projects-header .title {
	 display: inline-block;
}
 .projects-header .count {
	 color: #738297;
}
 .projects-header .zmdi {
	 cursor: pointer;
	 float: right;
	 font-size: 16px;
	 margin: 5px 0;
}
 .projects-header .title {
	 font-size: 21px;
}
 .projects-header .title + .count {
	 margin-left: 5px;
}
 .projects-table {
	 background: #273142;
	 width: 100%;
}
 .projects-table td, .projects-table th {
	 color: white;
	 padding: 10px 22px;
	 vertical-align: middle;
}
 .projects-table td p {
	 font-size: 12px;
}
 .projects-table td p:last-of-type {
	 color: #738297;
	 font-size: 11px;
}
 .projects-table th {
	 background-color: #313d4f;
}
 .projects-table tr:hover {
	 background-color: #303d52;
}
 .projects-table tr:not(:last-of-type) {
	 border-bottom: 1px solid #313d4f;
}
 .projects-table .member figure, .projects-table .member .member-info {
	 display: inline-block;
	 vertical-align: top;
}
 .projects-table .member figure + .member-info {
	 margin-left: 7px;
}
 .projects-table .member img {
	 border-radius: 50%;
	 height: 32px;
	 width: 32px;
}
 .projects-table .status > form {
	 float: right;
}
 .projects-table .status-text {
	 display: inline-block;
	 font-size: 12px;
	 margin: 11px 0;
	 padding-left: 20px;
	 position: relative;
}
 .projects-table .status-text:before {
	 border: 3px solid;
	 border-radius: 50%;
	 content: "";
	 height: 14px;
	 left: 0;
	 position: absolute;
	 top: 1px;
	 width: 14px;
}
 .projects-table .status-text.status-blue:before {
	 border-color: #1c93ed;
}
 .projects-table .status-text.status-green:before {
	 border-color: #66b92e;
}
 .projects-table .status-text.status-orange:before {
	 border-color: #da932c;
}
 .projects-table .status-text.status-red:before {
	 border-color: #d65b4a;
}
 .selectric {
	 background-color: transparent;
	 border-color: #313d4f;
	 border-radius: 4px;
}
 .selectric .label {
	 color: #738297;
	 line-height: 34px;
	 margin-right: 10px;
	 text-align: left;
}
 .selectric-wrapper {
	 float: right;
	 width: 150px;
}
 .chart {
	 border-radius: 3px;
	 border: 1px solid #313d4f;
	 color: white;
	 padding: 10px;
	 position: relative;
	 text-align: center;
}
 .chart canvas {
	 height: 400px;
	 margin: 20px 0;
	 width: 100%;
}
 .chart .actions {
	 margin: 15px;
	 position: absolute;
	 right: 0;
	 top: 0;
}
 .chart .actions span {
	 cursor: pointer;
	 display: inline-block;
	 font-size: 20px;
	 margin: 5px;
	 padding: 4px;
}
 .chart .actions .btn-link {
	 color: white;
}
 .chart .actions .btn-link i {
	 font-size: 1.75rem;
}
 .chart .title {
	 font-size: 18px;
	 margin: 0;
	 padding: 15px 0 5px;
}
 .chart .title + .tagline {
	 margin-top: 10px;
}
 .chart .tagline {
	 font-size: 12px;
}
 .danger-item {
	 border-left: 4px solid #a84d43;
}
 .zmdi {
	 line-height: 1;
	 vertical-align: middle;
}
.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
 background-color: #FFF !important;
 box-shadow: inset -1px 0 0 rgba(f#FFF, #FFF, #FFF, .25) !important;
 box-shadow: none !important;

}
@media only screen and (max-width: 767px) {
  .wrappers {
      border-radius: 19px;
      position: relative;
      margin-bottom: 30px;
  }
  .programTitleuser {
      margin-bottom: 15px;
  }
  .rowpadingsp {
      margin-top: 0px!important;
  }
  .video-gallery {
      margin: 20px auto 0;
  }

  .programsSection {
      margin-top: 0px !important;
  }
  .sideBarFooter {
      display: flex;
      gap: 5px;
      margin-top: 20px;
  }
  
  .commentbox{
  padding-bottom:0px;
  }

  .commentbox{
  padding:0px;
  }
  .loadBtn {
     margin-bottom: 0px  !important;
     margin-top: 0px  !important;
      text-align: center;
  }

 
  .background1 {
      padding-top: 30px!important;
  }
  
  

  
  .wrappers {
      border-radius: 19px;
      position: relative !important;;
      margin-bottom: 30px;
  }
  .programTitleuser {
      margin-bottom: 15px;
  }
  .rowpadingsp {
      margin-top: 0px!important;
  }
  .video-gallery {
      margin: 20px auto 0;
  }

  .programsSection {
      margin-top: 0px !important;
  }
  .sideBarFooter {
      display: flex;
      gap: 5px;
      margin-top: 20px;
  }

  .commentbox{
  padding-bottom:0px !important;
  }

  .commentbox{
  padding:0px !important;
  }
  .loadBtn {
     margin-bottom: 0px  !important;
     margin-top: 0px  !important;
      text-align: center;
  }
 
  footer.footerSection {
      background-image: linear-gradient(0deg,#ececec,#ececec,#ececec03);
      margin-top: 0px;
      padding:0px !important;
  }

  .background1 {
      padding-top: 30px!important;
  }

  .sideBarFooter {
      padding-bottom: 15px;
  }
  .subscirpSection {
      width: 100%;
  }
  ul.tandcLink {
      justify-content: left;
  }
  

  /* .wrappers {
      border-radius: 19px;
      position: absolute !important;;
  } */
  .background.mobileCssBG {
      height: 100%;
      padding-top: 350px;
  }
  .bannerHeading h1 {
      font-size: 30px;
  }
  .headerImage {
      height: 480px;
      background-position: -240px 0px;
  }
  .pulsating {
      height: 156px;
      border-top-left-radius: 45px!important;
      border-bottom-left-radius: 45px!important;
      width: 28px;
  }
  .programsSideBar {
      margin-top: 271px !important;
  }
  .call-fixed-button1 {
      right: -13px;
      top: 490px;
  }
  .pulsating p {
      padding: 0;
  }
  .onlinecousectinstu1 {
  margin-top: 22px !important;;
  }
  .programsSection3 {
      margin-top: 20px !important;;
  }
}


@media (min-width: 767px) and (max-width:992px) {
.wrappers.py-5.p_position {
  bottom: 40px !important;
}
.video-gallery {
  margin: 38px auto 0;
}




.sideBarFooter {
  margin-top: 20px;
}
.commentbox {
  margin: 8px 0;
 padding: 24px 0px 0px 0px !important;
}
.loadBtn {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  text-align: center;
}

}








@media (max-width:992px){
  footer.footerSection {
    margin-top: 50px !important;
    padding:0px !important;
}

.sideBarFooter {
    padding-bottom: 15px;
}
.subscirpSection {
    width: 100%;
}
ul.tandcLink {
    justify-content: left;
}


.filterArea .divider {
  margin-bottom: 0px !important;
}
.searchBox{
  margin-top: 0px !important;
}
}

hr.jobborder {
  background: #000 !important;
  color: #000 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.des {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
  word-wrap: break-word !important;
  height: 70px;
  color: #696969;
  font-size: 12px !important;
}
/* small devic  */


@media (max-width:768px){
  label {
    font-size: 12px !important;
}
i.fa.fa-camera.me-2.position-absolute.start-0.fs-1.text-light {
  margin-left: 8px !important;
  font-size: 15px !important;
  margin-top: 5px !important;
}
  .profileIconPost, .postPImage, .commentUserImage, .groupIcon {
    width: 40px !important;
    height: 40px !important;
    border-radius: 5px;
    overflow: hidden;
}
  p.heading {
    padding: 0px 20px !important;
}
  .headerImageuesr {
   
    background-position: inherit !important;
    background-repeat: no-repeat;
    background-size: cover;
}
  p.heading {
    font-size: 20px;
}
h6.jobsearch {
  font-size: 24px;
  margin-bottom: 20px !important;
 
}
  .row.rowpadingsp {
    display: none;
}
  .subscribeNow ul li {
    display:contents !important;
    width: 100% !important;
    padding: 15px 25px !important;
}
.bannerHeadingjob h1 {
  font-size: 40px !important;
  /* font-family: 'Lato' !important; */
  font-weight: 700 !important;
  margin-bottom: 30px !important;
}
.subscribeNow {
  width: 100% !important;
  padding: 10px !important;
}
  h6.jobsearch {
    margin-bottom: 0px;
    margin-top: 40px !important;
}
  .wrappers.p_position {
    margin-top: 30px !important;
    bottom: 20px !important;
}
.col-lg-8.col-xl-8.rowpadresponshive {
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}
  a.nav-item.nav-link.noti {
    border-radius: 50% !important;
    /* width: 60px; */
    background-color: #fff1f1;
    width: 20%;
    padding: 10px !important;
    margin: 20px !important;
}
button#dropdown-basic img {
  width: auto !important;
  border-radius: 50%;
  background-color: #e6dfdf;
  height: 49px;
}
  .wrappers {
    border-radius: 19px;
    position: relative;
    margin-bottom: 30px;
}
li.about {
  width: 100% !important;
}
.programTitleuser {
    margin-bottom: 15px;
}

.rowpadingsp {
    margin-top: 0px!important;
}
.video-gallery {
    margin: 20px auto 0;
}

.programsSection {
    margin-top: 0px !important;
}
.sideBarFooter {
    display: flex;
    gap: 5px;
    margin-top: 20px;
}

.commentbox{
padding-bottom:0px !important;
}


.loadBtn {
   margin-bottom: 0px  !important;
   margin-top: 0px  !important;
    text-align: center;
}

footer.footerSection {
    background-image: linear-gradient(0deg,#ececec,#ececec,#ececec03);
    margin-top: 10px !important;
}

.background1 {
    padding-top: 210px!important;
}
.tags ul li a {
  background-color: #3d55a5;
  padding: 5px 10px;
  color: #fff;
  /* margin: 9px 0px !important; */
  font-size: 18px;
  border-radius: 5px;
}
button.btn.d-md-none.btn-primary.rounded.btnSidebar.fw-bold.mt-0.mb-0.mb-md-2.mb-lg-0.bg-lgrey.text-dark {
  color: #fff !important;
}
.des {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
  word-wrap: break-word !important;
  height: 70px;
  color: #696969;
  font-size: 16px !important;
}
.commentbox {
  padding: 17px 10px;
  margin: 0px 0px !important;
}
.tags.job ul li a {
  background-color: #3d55a5 !important;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.loadBtn {
  margin-bottom: 0px !important;
  margin-top: 30px !important;
  text-align: center;
}
}

.btnConselling {
    border-radius: 0 0 5px 5px!important;
    color:#fff!important;
}

.rightSection .searchBox ul li img {
  background-color: unset;
  border: none;
  margin: 0 auto;
  padding: 0px;
  border-radius:1rem !important;
}
 


.sideBarFooter {
    display: flex;
    gap: 5px;
    margin: 15px 0;
}

.home_img {
  width: 100% !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
}
.home_img img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  max-width: 135px !important;
  max-height: 135px !important;
}

@media (max-width:600px){
  .home_img{
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: left;
  }
  .searchPanel h2 {
    font-size: 1.5rem!important;
    padding-right: 26px;
}
.home_img{
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content:left;
  min-width: 100px;
  min-height: 100px;
 
  
}
.pp{
  background-color: #fff !important;
}

}

@media (min-width:768px) and (max-width:1200px){
  h4.gNamell{
    font-size: 9px !important;
  }
}

.dropdown-toggle{
vertical-align: middle;
line-height: 40px;
}
@media (max-width:400px){
  .searchPanel h2 {
    font-size: 1.2rem!important;
    padding-right: 26px;
}

}
@media (max-width:360px){
  .pl_5{
    padding-left:18px;
  }
  
  .searchPanel h2 {
    font-size: 12px!important;
    padding-right: 26px;
}
.slider .searchPanel .btnSearch {
  padding: 10px 15px;
}
}
@media (max-width:300px){
  .pl_5{
    padding-left:25px;
  }
}





.search_engine .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 0 !important;
}
.search_engine .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-segi59{
  padding: 0 !important;
}

.addPost .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  min-height: 200px !important;
}


.fit{
  object-fit: cover !important;
}
.cusmy{
  width: calc(14.2% - 2px) !important;
  margin: 5px !important;
  padding: 5px !important;
}
.findJobHome{
  background: url(./img/jobsHome.jpg) no-repeat;
  background-size: cover;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greyBG{
  background-color: rgba(22, 22, 22, .7);
  width:100%;
  height:100%;
  top:0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  min-height: 350px;
  padding:250px 40px 50px 40px;
  text-align: center;
}
.findJobHome h2{
  color:#fff;
  font-weight: bold;
}

.Time_style .css-1xhypcz-MuiStack-root>.MuiTextField-root{
  min-width:0px !important;
}

.Time_style .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
  padding: 0px;
  width: 100%;
  height: 100%;
}
p {
   word-wrap: break-word;
}
h2 {
  word-wrap: break-word !important;
}
.MuiBox-root.css-0 h2 {
  word-wrap: break-word !important;
}
.wrappers {
  position: relative;
  max-width: 390px !important;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
  margin: 0 auto;
}
img.csss {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 52%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  position: absolute;
width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 99999;

  padding: 0px;
  border-radius: 20px;
}


.bgWhite{
  background:white;
  box-shadow:0px 3px 6px 0px #cacaca;
}

.title{
  font-weight:600;
  margin-top:20px;
  font-size:24px
}

.customBtn{
  border-radius:0px;
  padding:10px;
}

form input{
  display:inline-block;
  width:50px;
  height:50px;
  text-align:center;
}
input.otp {
  margin: 10px !important;
  border: 1px solid;
}
i.fa.fa-window-close {
  color: #ff2e2e85;
  font-size: 20px;
}
ul.rankingList {
  color: #fff;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 900px) and (max-width: 1100px) {
  button.btn.btn-primary.btnSidebar
   { border-radius: 20px; padding: 6px 6px; font-size: 15px; 
    background-color: #3D55A5; border: none; font-family: 'Lato'; 
    margin-top: 10px; min-width:100px!important; margin-right:5px; }
}.rightSection1 {
  margin-top: 110px;
  color: red;
  background-color: #cccccc3d;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border: 1px solid #cccccc4d;
  border-radius: 4px;
}
.slider1 {
  
  background: url(../img/userback.png) no-repeat 0 0;
  background-size: 100%;
  min-height: 520px;
  
  background-position: center;
}
.slider1 .gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  bottom: 0;
  min-height: 380px;
}
.searchPanel.job {
  top: 80%;
}
.groupName p {
  font-size: 16px;
}
.daysAndDetails p {
  font-size: 12px !important;
  font-weight: 600;
  color: #454545;
  margin-top: 20px !important;
}
.Apply {
  /* width: 105px; */
  font-size: 12px;
  padding: 4px 19px;
}
.Apply {
  text-align: center;
  background-color: #FFA845;
  border-radius: 5px;
  color: #454545;
}
.login_btn 
{ 
background-color: #3d55a5;
  color: #fff !important;
  border: 1px solid;
  border-radius: 5px;
}

button#dropdown-basic img {
  width: 34px !important;
    border-radius: 50%;
    background-color: #e6dfdf;
    height: 32px !important;
 
}
.dropdown {
  background-image: none;
  background-repeat: no-repeat;
  background-position: right center;
}
a.nav-item.nav-link.noti {

  border-radius: 50% !important;
  /* width: 60px; */
  background-color: #fff1f1;
  width: 50%;
}

a.nav-item.nav-link.noti {
  background-color: initial!important;
  border-radius: 50%!important;
  margin: 0!important;
  padding: 7px 11px!important;
  background-color: #3C3C3C !important;
  width: 100%;
  height: auto !important;
  color: #fff !important;
}
input, input[type="text"], input[type="search"], isindex, textarea, button {
  outline: none;
  margin: 0 auto;
  text-align: left;
  padding: 5px 10px;
  box-sizing: border-box;
}
h1.subscribeTitle {
  font-size: 20px!important;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}
.dropdown {
    /* background-image: none !important; */
    background-repeat: no-repeat;
    background-position: right center;
   
}
button#dropdown-basic:hover {
  background-color: transparent !important;
  border: none !important;
}
.num-count {
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  font-size: 0.6rem;
  background: #169e11;
  width: 1.2rem;
  height: 1.2rem;
  color: #ecf0f1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 8px;
  /* right: -0.2px !important; */
  box-sizing: border-box;
}
#dropdown-basic {
  margin-right: 10px;
}
button#dropdown-basic {
  border: none !important;
}
.col-lg-8.col-xl-8.rowpadresponshive h2 {
  font-weight: 600;
}
.tabPanel.rounded {
  border-radius: 5px !important;
}
button.btn.btn-primary.btnBSidebar.RefilterBtn.mt-3 {
  border-radius: 5px !important;
}

p.MuiTypography-root.MuiTypography-body1.css-193rcbo-MuiTypography-root {
  text-align: center !important;
}
p.MuiTypography-root.MuiTypography-body1.px-2.px-lg-0.fs10-s.css-1e4icwu-MuiTypography-root {
  text-align: center;
}
.des{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
  word-wrap: break-word !important;
  height: 70px;
}
.filterBody {
  padding: 10px;
}

.filterArea {
  margin-bottom: 10px;
}
button.btn.btn-primary.btnSidebar.filterBtn {
  color: #fff !important;
}
input.form-control.selectOption.selected {
 
  margin: 15px 0px !important;
  height: 32px !important;
  text-align: left;
  background-color: #ececec;
  font-size: 12px !important;
  border-radius: 27px;
  font-style: italic;
}
#submitButton {
    color: #fff !important;
    font-size: 16px !important;
    background-color: #3D55A5 !important;
    border-radius: 26px !important;
    font-size: 16px !important;
    width: auto;
    padding: 8px 25px;
    justify-content: center;
    text-align: center;
    margin: 0px 0px;
    text-align: center;
    justify-content: center;
    /* display: flex; */
    /* align-items: center; */
}
@media only screen and (min-width: 768px) {
  .tags {
    display: none;
}
}

.groupName p {
  font-size: 16px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
  word-wrap: break-word !important; */
  /* min-width: 3000px; */
}
header.bg-surface-primary.border-bottom.pt-6 {
width: 1220px !important;
}
.form-group img {
  width: 22%;
}
.slider .searchPanel input[type="text"] {
  border-radius: 26px;
  background-color: #f9f9f9;
  padding: 25px 45px;
  font-weight: 500;
  width: 500px !important;
}
.swiper{
  padding:45px 0!important;
}
.jobRequirementPanel h2 {
  color: #3D55A5;
  font-weight: 800 !important;
  font-size: 32px !important;
  padding: 15px 0 0 0;
}
.jobRequirementPanel .text-center{
  text-align: center;
}
.btn-primary{
  background-color: #3D55A5!important;
  border:0!important;
  padding:6px 15px!important;
}
.certificateCourse{
  background-image: url("./img/online_courses.png");
  background-repeat: no-repeat;
  margin: 45px 0;
  min-height: 300px;
  position: relative;
  padding: 100px 0;
}
.certificateCourse .overlayBlack{
  position: absolute;
  background: rgba(0,0,0,0.5);
  left:0;
  top:0;
  width:100%;
  height: 100%;
}
.colorWhite{
  color:#fff!important;
}
.textUpOverlay{
  position:relative;
  z-index: 999;
}
.colorBlue{
  color:#3D55A5!important;
  font-weight:bold;
}
.tags ul{
  list-style: none;
  margin:0;
  padding:0;
}
.tags{
  padding:15px 0;
}
.tags ul li{
  margin: 5px 10px 5px 0px;
  display:inline-block
}
.tags ul li a{
  background-color: #3C3C3C;
  padding:5px 10px;
  color:#fff;
  border-radius: 5px;;
}
.careerPath{
  background-image: url("./img/1637330549549.jpg");
  background-size: 100%;
  background-position: 0px -200px;
}
.p5{
  padding:100px 0;
}
.p15{
  padding:15px 0!important;
}
.m5{
  margin-top:20px;
}
.tradeCommunity h2{
  font-size: 50px!important;
}
.loginPanel input[type="text"], .loginPanel input[type="password"]{
  padding:10px 15px!important;
}
.orPosition{
  margin-bottom:25px;
}
.btn-outline-primary {
  border: 1px solid #3d55a5 !important;
  color: #3d55a5 !important;
  padding: 7px 18px !important;
  text-transform: uppercase;
}
.btn-outline-primary:hover{
  background-color:#3d55a5 !important;
  color:#fff!important;
}
.subscribeNow{
  width:50%;
  margin:25px auto;
}
.subscribeNow ul{
  list-style:none;
  margin:0;
  padding:0;
}
.subscribeNow ul li{
  display:inline-block;
  width:50%;
  padding:15px 15px;
} 
.subscribeNow ul li.text-right{
  text-align:right;
}
.subscribeNow ul li input[type="text"]{
  border:1px solid #c4c4c4;
  border-radius: 5px;
}
.subscribeNow ul li .subscribeBtn{
  border-radius: 5px;
  font-size: 14px!important;
  text-transform: uppercase;
  font-weight:bold;
}
.navigation-menu li .dropdown-menu a {
  width: 100%;
  margin: 0 0px;
  border-bottom: 1px dotted #ccc;
  font-size: 13px;
  line-height: 35px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.login_btn {
  background-color: #3d55a5;
  color: #fff !important;
  border: 1px solid;
  border-radius: 5px;
  gap: 30px !important;
  margin-right: 5px !important;
}
.btn-outline-primary {
  color: #fff;
  border-color: #0d6efd;
  margin-right: 10px !important;
  color: #0000 !important;
  border-radius: 5px !important;
}
.btn-outline-primary,.btn-primary-outline a{
  color:#3d55a5!important;
}
.btn-outline-primary:hover,.btn-primary-outline a:hover{
  color:#fff!important;
}
p.mess {
  text-align: center;
  font-size: 18;
  color: green !important;
  font-size: 15px !important;
}
.successMsg{
  color:#215e10;
}
.errorMsg{
  color:#ae0d0d;
}
.mainHeader{
  min-height: 80px;
}
.button {
  background-color: #3D55A5 !important;
  border-radius: 26px !important;
  font-size: 16px !important;
  width: 50%;
  justify-content: center;
  text-align: center;
  margin: 0px 50px;
}
.wrappers.p_position {
  bottom: -56px;
}
h6.jobsearch {
  font-size: 20px;
  margin-top: 35px !important;
  color: #454545 !important;
}
h3.text-black.jobhrad {
  margin: 10px 22px;
}
select.form-control.selectOptionSidebar.job {
  padding: 6px;
  font-size: 12px;
}


.apply_box {
  max-width: 800px;
  padding: 20px;
  background-color: #ccc0;
  margin: 0 auto;
  margin-top: 50px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 15px;
}
.apply_box h1{
  margin-bottom: 45px;
}
.small{
  font-size: 17px;
}
.form_container{
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  gap: 15px;
}
.apply_box  textarea{
  height:60px;
  resize:none;
}
.form_control{
  display: flex;
  flex-direction: column;

}
label{
  font-size: 18px;
  margin-bottom: 5px;
}
input,select,textarea{
  padding: 8px 10px;
 
  border-radius: 5px;
  font-size: 13px;
}

.button_container{
  display: flex;
  justify-content: end;
  margin-top: 20px;
  padding: 5px;
}
button{
  background-color: rgb(61 85 165);
      border-radius: 5px;
     
      padding: 5px 10px;
      color: whitesmoke;
      padding: 10px;
}
button:hover{
  background-color: rgb(15, 117, 225);
  color: rgb(15, 14, 14);
  cursor: pointer;
}
.textarea_control {
  grid-column: 1 / span 2 ;
  
}
.textarea_control textarea{
  width: 100%;
}
form.stu input {
  width: 100% !important;
  text-align: left !important;
}
.apply_box,.posRelative {
  position: relative;
}
.apply_box h1 {
  color: #3d55a5;
  font-size: 25px;
}
.position-relative.program {
  padding: 0px 52px;
}
button.btn.btn-primary.button.btnSearch {
  width: 40px;
}
a.btn.btn-primary.btnConselling {
  color: #fff !important;
}
.inputContainer {
  height: 132px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fileupload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}
.apply_box .form_control{
  margin: 10px 0!important;
}
.apply_box select{
  height: 50px!important;
}
p.sucress {
  text-align: right;
  color: #0e8f07;
}
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
  display: none !important;
}
.input-box {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
button.submitButtons {
  padding: 5px 15px !important;
}
h5.proheading {
  font-size: 16px;
}
input#file-input {
  display: none;
}

button.px-5.mb-3.py-2.mx-1.rounded-2.bg-white.border-0 p {
  color: #000 !important;
}
i.fa.fa-angle-down.fw-bold.fs-2 {
  color: #000;
}
button.ps-5.py-2.ms-2.py-2.hh.border-0 {
  background-color: #ececec !important;
}
.profileIconPost, .postPimg, .commentUserImg, .groupIcon {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  overflow: hidden;
}
.position-absolute {
  left: 3px!important;
  font-size: 28px !important;
  top: 3px !important;
}
img.imgnotification {
  height: 34px;
  border-radius: 50%;
  width: 45px;
}
.menu-notification.dropdown-menu.show {
  width: 240px;
}

a.imgdis.dropdown-item {
  background-color: #d6ddf58a;
  display: flex;

  justify-content: space-between;
}
img.imgnotification {
  height: 38px;
  border: none !important;
  width: 40px;
  border-radius: 50% !important;
}
.dropdown-menu.show {

  border: none;
}
span.contentspost1 {
  clear: #ccc !important;
  color: #9b9ebc !important;
}
span.contentspost {
  color: #4c62ac8a;
}

a.imgdis.rr.dropdown-item .contentspost {
  color: #000 !important;
}
.menu-notification.dropdown-menu.show {
  border: none;
}
a.imgdrapdrap.dropdown-item {
  background-color: #4c62ac;
  height: 40px;
}
a.imgdis.rr.dropdown-item {
  background-color: #ffffff;
}
span.contentspost {
  line-height: 20px;
  word-wrap: break-word;
  width: 150px !important;
}
.menu-notification.dropdown-menu.show:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 15px;
  border: 10px solid;
  border-color: transparent transparent #4c62ac transparent;
}
.dropdown-menu.show {
  background-color: #f5f5f5;
}
.main-box-futer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.futureBox {
  width: 250px !important;
  align-items: center;
  margin-top: 10px;
}
.onlinecousectinstu1 svg {
  height: 205px;
}



.panel-default>.panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.panel-default>.panel-heading a {
  display: block;
  padding: 10px 15px;
}

.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.panel-default>.panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
  content: "\2212";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
button#pills-profile-tab {
  background-color: transparent;
}
button#pills-contact-tab {
  background-color: transparent;
}
.nav-link:hover, .nav-link:focus {
  color: #3d55a5 !important;
  border-bottom: 3px solid #3d55a5 !important;
}
button#pills-home-tab {
  background-color: transparent;
}
button.fs-2.custom-btn.border-0 {
  background-color: #ececec;
  color: #3d55a5;
}
ul.margen {
  text-align: end;
}
i.fa.fa-camera.me-2.position-absolute.start-0.fs-1.text-light {
  margin-left: 8px ;
  font-size: 25px !important;
  margin-top: 5px ;
}
label {
  font-size: 16px;
}
i.fa.fa-angle-down:hover {
  border: none;
}
li.dawn-left {
  text-align: end;
}
.bg-white {
  width: 99.8%;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.dropdown-toggle::after {
    display: none !important;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
i.fa.fa-angle-down {
  font-size: 40px;
}
button.btn.btn-primary.btnSidebar1.filterBtn.position-relative.bg-dark {
  margin-bottom: 20px !important;
}
.groupIcon.jobimg {
  border-radius: 10px !important;
  width: 200px !important;
  height: 120px !important;
}
a.imgdrapdrap.dropdown-item {
  margin-top: -3.2% !important;
}
.menu-notification.dropdown-menu.show {
  margin-top: 10px;
}
.menu-notification.dropdown-menu.show {
  border: 1px solid #4c62ac26;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.cusmy.rounded-pill.css-lxp3rb-MuiButtonBase-root-MuiButton-root:hover {
  background-color: white !important;
  border-radius: 30px !important;
  border: 1px solid #4c62ac;
  color: #4c62ac;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.d-flex.justify-content-center.align-items-center.rounded-pill.css-1r0395d-MuiButtonBase-root-MuiButton-root:hover {

  background-color: white !important;
  border-radius: 30px !important;
  border: 1px solid #4c62ac !important;
  color: #4c62ac !important
 

}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.cusmy.rounded-pill.css-1e1e9zn-MuiButtonBase-root-MuiButton-root:hover{ 
  background-color: white !important;
  border-radius: 30px !important;
  border: 1px solid #4c62ac !important;
  color: #4c62ac !important
 
}
